import CheckIcon from "@mui/icons-material/Check";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import SendIcon from "@mui/icons-material/Send";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Box, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useUserData } from "../../user";
import CustomButton from "../global/CustomButton";
import CustomTabsTable from "../global/CustomTabsTable";
import { Loading } from "../global/Loading";

const AnnouncementsTable = ({
  announcements,
  onEdit,
  onApprove,
  onSend,
  onDownload,
  onDelete,
  loading,
}) => {
  const userData = useUserData();
  const [chosenAnnouncement, setChosenAnnouncement] = useState();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    topic: true,
    message: true,
    scheduleSentDate: true,
    dateSent: false,
    scheduleDate: false,
    actions: true,
    report: true,
  });

  const tabsFunctions = {
    all: {
      func: function (data) {
        return data;
      },
      label: "All",
      color: "default",
    },
    isApproved: {
      func: function (data) {
        return data.filter(
          (announcement) =>
            announcement.isApproved &&
            !announcement.scheduleDate &&
            !announcement.dateSent
        );
      },
      label: "Approved",
      color: "green",
    },
    isScheduled: {
      func: function (values) {
        return values.filter(
          (announcement) =>
            announcement.isApproved &&
            announcement.scheduleDate &&
            !announcement.dateSent
        );
      },
      label: "Scheduled",
      color: "blue",
    },
    isDraft: {
      func: function (values) {
        return values.filter((announcement) => !announcement.isApproved);
      },
      label: "Draft",
      color: "yellow",
    },
    isArchived: {
      func: function (values) {
        return values.filter((announcement) => announcement.dateSent);
      },
      label: "Archived",
      color: "gray",
    },
  };

  const columns = [
    {
      field: "topic",
      headerName: "Topic",
      cellClassName: "topic-column--cell",
      flex: 1,
    },
    {
      field: "message",
      headerName: "Message",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
            overflow: "hidden",
          }}
          dangerouslySetInnerHTML={{ __html: params.value || "" }}
        />
      ),
    },
    {
      field: "scheduleSentDate",
      headerName: "Schedule/Sent Date",
      cellClassName: "scheduleSentDate-column--cell",
      flex: 1,
      headerAlign: "center",
      valueGetter: (params) => {
        const dateSent = params.row.dateSent;
        const scheduleDate = params.row.scheduleDate;
        if (dateSent || scheduleDate) {
          const date = new Date(dateSent || scheduleDate);
          const formattedDate = date.toLocaleDateString(undefined, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            timeZone: "UTC",
          });
          return {
            formattedDate: formattedDate,
            isSentDate: !!dateSent,
          };
        }
        return {
          formattedDate: "",
          isSentDate: false,
        };
      },
      renderCell: (params) => {
        const { formattedDate, isSentDate } = params.value;
        const SentIcon = (
          <Tooltip title="Sent date">
            <TaskAltIcon fontSize="small" />
          </Tooltip>
        );
        const ScheduledIcon = (
          <Tooltip title="Schedule date">
            <ScheduleSendIcon fontSize="small" />
          </Tooltip>
        );

        let iconToRender = "";
        if (formattedDate) {
          iconToRender = isSentDate ? SentIcon : ScheduledIcon;
        }

        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {iconToRender}
            {formattedDate && <Box sx={{ ml: "7px" }}>{formattedDate}</Box>}
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const disabled = !!(
          (params.row.isApproved && params.row.scheduleDate) ||
          loading ||
          (params.row.isApproved && params.row.dateSent)
        );
        const announcementData = {
          id: params.row.id,
          topic: params.row.topic,
          message: params.row.message,
          subscriptionIds: params.row.subscriptions.map((sub) => sub.id),
          scheduleDate: params.row.scheduleDate,
          dateSent: params.row.dateSent,
        };
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <>
              <CustomButton
                type={"edit"}
                buttonText={"Edit"}
                onClick={() => onEdit(announcementData)}
                sx={{
                  padding: "5px 10px 5px 5px",
                  fontSize: "0.675rem",
                  width: "80px",
                }}
              />
              {userData.isAdmin && (
                <>
                  <Tooltip
                    title={
                      disabled
                        ? "You can't manually send scheduled or archived announcements"
                        : ""
                    }
                  >
                    <span>
                      <CustomButton
                        buttonText={params.row.isApproved ? "Send" : "Approve"}
                        icon={params.row.isApproved ? SendIcon : CheckIcon}
                        disabled={disabled}
                        onClick={
                          params.row.isApproved
                            ? () => {
                                setChosenAnnouncement(announcementData);
                                onSend(announcementData);
                              }
                            : () => onApprove(announcementData)
                        }
                        sx={{
                          padding: "5px 10px 5px 5px",
                          fontSize: "0.675rem",
                          width: "80px",
                        }}
                      />
                    </span>
                  </Tooltip>

                  <CustomButton
                    buttonText={"Delete"}
                    icon={DeleteIcon}
                    disabled={params.row.isApproved && loading}
                    onClick={() => onDelete(announcementData)}
                    sx={{
                      padding: "5px 10px 5px 5px",
                      fontSize: "0.675rem",
                      width: "80px",
                    }}
                  />
                </>
              )}
            </>
          </Box>
        );
      },
    },
    {
      field: "report",
      headerName: "Report",
      headerAlign: "center",
      width: "100",
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
            }}
          >
            {loading && chosenAnnouncement?.id === params.row?.id ? (
              <Loading />
            ) : (
              <>
                <CustomButton
                  icon={CloudDownloadIcon}
                  disabled={params.row.reports.length == 0}
                  onClick={() => onDownload(params.row.reports[0].reportId)}
                  sx={{
                    padding: "5px 5px 5px 5px",
                    fontSize: "0.675rem",
                    width: "80px",
                  }}
                />
              </>
            )}
          </Box>
        );
      },
    },
  ];
  return (
    <CustomTabsTable
      data={announcements}
      columns={columns}
      name={"announcements"}
      tabs={tabsFunctions}
    />
  );
};

export default AnnouncementsTable;
