import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useUserData } from "../../user";
import { useLocalState } from "../../utils/hooks/global/useLocalStorage";

const CreateEditAnnouncementForm = ({ announcement, onSubmit, mode }) => {
  const [availableSubs, setAvailableSubs] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [jwt] = useLocalState(null, "jwt");
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [formData, setFormData] = useState(announcement);
  const userData = useUserData();

  const handleAutocompleteChange = (event, value) => {
    const selectedIds = value.map((sub) => sub.id);
    setFormData((prevData) => ({
      ...prevData,
      subscriptionIds: selectedIds,
    }));
  };

  const handleInputChange = (e) => {
    setErrorMessage("");
    let { name, value } = e.target;

    if (name === "scheduleDate") {
      value = value ? toUTCNoon(value) : null;
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const toUTCNoon = (dateString) => {
    const dateParts = dateString.split("-");

    const utcDate = new Date(
      Date.UTC(
        parseInt(dateParts[0], 10),
        parseInt(dateParts[1], 10) - 1,
        parseInt(dateParts[2], 10),
        12,
        0,
        0,
        0
      )
    );

    return utcDate.toISOString();
  };

  useEffect(() => {
    fetch(`${backendUrl}/channels/subscriptions`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAvailableSubs(data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, [jwt, backendUrl]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const isTopicValid = formData.topic && formData.topic.trim() !== "";
    const isMessageValid =
      formData.message &&
      formData.message.replace(/<[^>]*>/g, "").trim() !== "";
    const isSubscriptionsValid =
      formData.subscriptionIds && formData.subscriptionIds.length > 0;
    const isScheduleDateValid = !formData.scheduleDate
      ? true
      : new Date() <= new Date(formData.scheduleDate);

    if (
      isTopicValid &&
      isMessageValid &&
      isSubscriptionsValid &&
      isScheduleDateValid
    ) {
      const url =
        mode === "update" && formData.id
          ? `${backendUrl}/announcements/${formData.id}`
          : `${backendUrl}/announcements/`;
      const method = mode === "update" ? "PUT" : "POST";
      if (mode === "update") {
        formData.isApproved = false;
        formData.isDraft = true;
      }
      fetch(url, {
        method: method,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify(formData),
      })
        .then((response) => {
          if (!response.ok) {
            if (response.status === 404) {
              throw new Error("Announcement not found!");
            } else {
              throw new Error("There was an error processing your request.");
            }
          }
          return response.json();
        })
        .then((data) => {
          setErrorMessage("");
          onSubmit();
        })
        .catch((error) => {
          console.error("There was an error!", error.message);
          setErrorMessage(error.message);
        });
    } else {
      console.log("Form data is invalid. Please check all fields.");
      setErrorMessage("Please check all fields.");
    }
  };

  const handlePreview = () => {
    fetch(`${backendUrl}/announcements/send-preview`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
      body: JSON.stringify({
        message: formData.message,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error("Announcement not found!");
          } else {
            throw new Error("There was an error processing your request.");
          }
        }
        return response.json();
      })
      .then((data) => {
        setErrorMessage("");
      })
      .catch((error) => {
        console.error("There was an error!", error.message);
        setErrorMessage(error.message);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack
        direction={"column"}
        width={"100%"}
        spacing={2}
        sx={{
          display: "flex",
          alignItems: "center",
          overflowX: "auto",
          maxHeight: "80vh",
          "&::-webkit-scrollbar": {
            width: "0px",
            height: "0px",
            background: "transparent",
          },
          scrollbarWidth: "none",
          MsOverflowStyle: "none",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <FormControl>
            <TextField
              id="topic"
              label="Topic"
              name="topic"
              variant="outlined"
              fullWidth
              required
              sx={{ flex: "0.8", width: "50%", mt: "10px" }}
              value={formData.topic}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "600",
                marginTop: "10px",
              }}
            >
              Message
            </Typography>
            <Box
              sx={{
                marginTop: "8px",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <ReactQuill
                theme="snow"
                style={{
                  marginBottom: "50px",
                  height: "30vh",
                }}
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline"],
                    [{ list: "bullet" }, "strike", "link"],
                    ["clean"],
                  ],
                }}
                placeholder="Write the announcement here"
                value={formData.message}
                onChange={(value) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    message: value,
                  }));
                }}
              />
            </Box>
          </FormControl>
          {availableSubs && (
            <FormControl>
              <Autocomplete
                name="subscriptionIds"
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={handleAutocompleteChange}
                value={availableSubs.filter((sub) =>
                  formData.subscriptionIds.includes(sub.id)
                )}
                sx={{
                  width: "50%",
                }}
                multiple
                id="tags-outlined"
                options={availableSubs}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Subscriptions"
                    placeholder="Add subscription"
                  />
                )}
              />
            </FormControl>
          )}
          <FormControl>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "600",
                marginTop: "10px",
              }}
            >
              Scheduled at
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextField
                id="scheduleDate"
                name="scheduleDate"
                variant="outlined"
                type="date"
                fullWidth
                sx={{ flex: "0.15", width: "15%", mt: "10px" }}
                value={(function () {
                  if (!formData.scheduleDate) {
                    return "";
                  }

                  const date = new Date(formData.scheduleDate);
                  if (isNaN(date.getTime())) {
                    return "";
                  }

                  return `${date.getUTCFullYear()}-${String(
                    date.getUTCMonth() + 1
                  ).padStart(2, "0")}-${String(date.getUTCDate()).padStart(
                    2,
                    "0"
                  )}`;
                })()}
                onChange={handleInputChange}
              />
              <Typography
                variant="body1"
                sx={{
                  mt: "10px",
                  flex: "0.85",
                  ml: "10px",
                  color: "#aeaeae",
                }}
              >
                12:00PM UTC Time
              </Typography>
            </Box>
          </FormControl>
        </Box>
        <Box
          sx={{
            flex: "0.2",
            justifyContent: "flex-end",
            textAlign: "center",
          }}
        >
          {errorMessage && (
            <Box sx={{ color: "red", marginTop: 2, marginBottom: 2 }}>
              {errorMessage}
            </Box>
          )}

          <Button
            disableElevation
            onClick={handlePreview}
            sx={{
              color: "white",
              borderRadius: "7px",
              textTransform: "none",
              fontWeight: "700",
              marginRight: "10px",
              backgroundColor: "rgb(255, 171, 0)",
              fontSize: "0.875rem",
              "&:hover": {
                backgroundColor: "#98a0a7",
                boxShadow: "none",
              },
            }}
            variant="contained"
            size="small"
            disabled={
              !!(
                !userData.isAdmin ||
                formData.subscriptionIds.length === 0 ||
                formData.message.replace(/<[^>]*>/g, "").trim() === "" ||
                formData.dateSent
              )
            }
          >
            Preview
          </Button>
          <Button
            disableElevation
            type="submit"
            sx={{
              backgroundColor: "black",
              color: "white",
              borderRadius: "7px",
              textTransform: "none",
              fontWeight: "700",

              fontSize: "0.875rem",
              "&:hover": {
                backgroundColor: "#98a0a7",
                boxShadow: "none",
              },
            }}
            variant="contained"
            size="small"
            disabled={
              !!(
                !userData.isAdmin ||
                formData.subscriptionIds.length === 0 ||
                formData.message.replace(/<[^>]*>/g, "").trim() === "" ||
                (formData.scheduleDate &&
                  new Date() > new Date(formData.scheduleDate)) ||
                formData.dateSent
              )
            }
          >
            Save
          </Button>
        </Box>
      </Stack>
    </form>
  );
};

export default CreateEditAnnouncementForm;
